import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Firebase radex configuration
const radexFirebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG_RADEX
);
const payezyFirebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG_PAYEZY
);
const tetherxFirebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG_TETHERX
);

const authProvider = new GoogleAuthProvider();

// Initialize Firebase app for radex
const radexApp = initializeApp(radexFirebaseConfig);
const radexAuth = getAuth(radexApp);
const radexFirestore = getFirestore(radexApp);

// Initialize Firebase app for payezy
const payezyApp = initializeApp(payezyFirebaseConfig, "app2");
const payezyAuth = getAuth(payezyApp);
const payezyFirestore = getFirestore(payezyApp);

// Initialize Firebase app for tetherx
const tetherxApp = initializeApp(tetherxFirebaseConfig, "app3");
const tetherxAuth = getAuth(tetherxApp);
const tetherxFirestore = getFirestore(tetherxApp);

// Export the necessary variables for each Firebase project
export {
  radexAuth,
  payezyAuth,
  tetherxAuth,
  authProvider,
  radexFirestore,
  payezyFirestore,
  tetherxFirestore,
};
