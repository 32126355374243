export const PZ_USER_ENUM = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Phone",
  address: "Address",
  totalAmountTransfers: "Total Amt Transfers",
  totalhashTransfers: "Total # Transfers",
  kycStatus: "KYC Status",
  idFrontSide: "ID [Front]",
  idBackSide: "ID [Back]",
};
